import { Injectable } from '@angular/core';
import { LimitReportPosition } from '@backoffice-frontend/limit-report/domain';
import { DataTableParameters } from '@clean-code/shared/common';
import { EditSearchDataService } from '@clean-code/shared/util/util-data-service';
import { ID, PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { LimitReportPositionPowerService } from './limit-report-position.service';

@Injectable({
  providedIn: 'root',
})
export class LimitReportPositionPowerListFacade
  implements EditSearchDataService<LimitReportPosition>
{
  constructor(private service: LimitReportPositionPowerService) {}

  getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<LimitReportPosition>> {
    return this.service.getAll$(params) as Observable<
      PaginationResponse<LimitReportPosition>
    >;
  }

  add$(
    entity: LimitReportPosition
  ): Partial<Observable<boolean | LimitReportPosition | ID>> {
    throw new Error('Method not implemented.');
  }
  update$(
    entity: LimitReportPosition
  ): Partial<Observable<boolean | LimitReportPosition | ID>> {
    throw new Error('Method not implemented.');
  }
  delete$(id: ID): Partial<Observable<boolean | void>> {
    throw new Error('Method not implemented.');
  }
  getById$(id: ID): Observable<LimitReportPosition> {
    throw new Error('Method not implemented.');
  }
}
